import React, { MouseEventHandler } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { Close } from '../../assets/Icons';

export interface ExplanationOverlayProps {
	title: string;
	text: string;
	button: MouseEventHandler<HTMLElement>;
	cancel?: MouseEventHandler<HTMLElement>;
	showButton?: boolean;
}
export default function ExplanationOverlay(props: ExplanationOverlayProps) {
	const { t } = useTranslation();

	return (
		<div className='explanation-overlay'>
			<div className='inner'>
				<div className='cancel' onClick={props.cancel}>
					{Close}
				</div>
				<h3>{t(props.title)}</h3>
				<p className='p2'>{t(props.text)}</p>
				<div>
					{props.showButton && (
						<>
							<button
								className='black small'
								onClick={props.cancel}
							>
								{t('ButtonCancel')}
							</button>
							<button
								className='red small'
								onClick={props.button}
							>
								{t('DeletePassword')}
							</button>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
