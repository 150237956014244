import React, { useEffect, useState } from 'react';
import './styles.scss';
import {
	CheckmarkPassword,
	CrossPassword,
	EyeHide,
	EyeShow,
	Info,
} from '../../assets/Icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Input from '../Input';
import ExplanationOverlay from '../ExplanationOverlay';

interface PasswordStrengthProps {
	errorMessage: any;
	value: string;
	id: string;
	onInput: (e: any) => void;
	entropy?: number;
}

const PasswordStrengthMeter: React.FC<PasswordStrengthProps> = props => {
	const [showPW, setShowPW] = useState<boolean>(false);
	const { t } = useTranslation();
	const [password, setPassword] = useState('');
	const [entropyBits, setEntropyBits] = useState(props.entropy);
	const [passwordLength, setPasswordLength] = useState(0);

	useEffect(() => {
		const newPassword = props.value;
		setPassword(newPassword);
		const calculatedPasswordLength = newPassword.length;
		setPasswordLength(calculatedPasswordLength);
		setEntropyBits(props.entropy);
	}, [props.value, props.entropy]);

	const [showPasswordInfo, setShowPasswordInfo] = useState(false);
	const showPasswordInfoHandler = () => {
		setShowPasswordInfo(!showPasswordInfo);
	};

	return (
		<div className='password-strength'>
			<Input
				{...props}
				labelHeadline={t('PasswordLabelNew')}
				labelId='label-new-pw'
				type={showPW ? 'text' : 'password'}
				onInput={props.onInput}
				value={password}
				placeholder={t('PasswordPlaceholderNew')}
				icon={
					<div className='show' onClick={() => setShowPW(!showPW)}>
						{showPW ? EyeHide : EyeShow}
					</div>
				}
			/>
			<div className='password-strength-criteria'>
				<div
					className={`password-strength-bar ${
						entropyBits && entropyBits >= 60
							? 'green'
							: entropyBits && entropyBits >= 53
								? 'yellow'
								: entropyBits &&
									  entropyBits > 0 &&
									  entropyBits < 53
									? 'red'
									: ''
					}`}
				>
					<div />
					<div />
					<div />
				</div>

				<p>
					<b>{t('PasswordStrength')} </b> <b> </b>
					<b>
						{entropyBits &&
						entropyBits >= 60 &&
						passwordLength &&
						passwordLength >= 16
							? t('passwordStrong')
							: (entropyBits && entropyBits >= 53) ||
								  (passwordLength && passwordLength >= 16)
								? t('passwordMedium')
								: (entropyBits &&
											entropyBits > 0 &&
											entropyBits < 53) ||
									  (passwordLength &&
											passwordLength > 0 &&
											passwordLength < 16)
									? t('passwordWeak')
									: ''}
					</b>
					<p>{t('PasswordStrengthText')}</p>
				</p>
				<p
					className={`${
						passwordLength && passwordLength >= 16
							? 'green'
							: passwordLength > 0
								? 'red'
								: ''
					}`}
				>
					{passwordLength && passwordLength >= 16
						? CheckmarkPassword
						: CrossPassword}

					{t('SufficientPasswordCharacters')}
					{' ' + '(' + passwordLength + ' ' + t('Of') + ' 16' + ')'}
				</p>
				<p
					className={`${
						entropyBits && entropyBits >= 60
							? 'green'
							: entropyBits && entropyBits >= 53
								? 'yellow'
								: passwordLength > 0
									? 'red'
									: ''
					}`}
				>
					{entropyBits && entropyBits >= 53
						? CheckmarkPassword
						: CrossPassword}

					{t('SufficientPasswordStrength')}
					{' ' +
						'(' +
						entropyBits +
						' ' +
						t('Of') +
						' 53 ' +
						t('EntropyUnit') +
						')'}
				</p>
				<div className='info' onClick={showPasswordInfoHandler}>
					{Info}
				</div>
			</div>
			{showPasswordInfo && (
				<ExplanationOverlay
					button={() => setShowPasswordInfo(false)}
					title={i18next.t('PasswordExplanationTitle')}
					text={'PasswordExplanationText'}
					cancel={() => setShowPasswordInfo(false)}
				/>
			)}
		</div>
	);
};

export default PasswordStrengthMeter;
