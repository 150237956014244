import { ScreenEnum } from '../../enums';
import React, { useContext, useState } from 'react';
import AppStateContext from '../../AppStateProvider/context';
import { useTranslation } from 'react-i18next';
import PasswordInput from '../../components/Input/passwordInput';
import './styles.scss';
import i18next from 'i18next';
import PasswordStrengthMeter from '../../components/PasswordStrengthMeter';
import zxcvbn from 'zxcvbn';
import ExplanationOverlay from '../../components/ExplanationOverlay';

export function Password(props: {
	saveBtn?: Function;
	passwordToggle?: Function;
	setkvps?: Function;
	triggerValidation?: boolean;
}) {
	const { state, dispatch } = useContext(AppStateContext);
	const { t } = useTranslation();
	const [newPw, setNewPw] = useState<string | false>('');
	const [confirmPw, setConfirmPw] = useState<string | false>('');
	const [showDeletePasswordWarning, setShowDeletePasswordWarning] =
		useState(false);
	let calculatedEntropyBits = undefined;
	if (typeof newPw === 'string') {
		const result = zxcvbn(newPw);
		calculatedEntropyBits = Math.round(
			Math.log2(Math.pow(10, result.guesses_log10))
		);
	}

	const deletePasswordHandler = () => {
		setShowDeletePasswordWarning(true);
	};

	const cancelBtnHandler = () => {
		props.passwordToggle && props.passwordToggle();
		props.triggerValidation
			? dispatch({
					type: 'SET_SCREEN',
					value: ScreenEnum.validation,
			  })
			: dispatch({
					type: 'SET_SCREEN',
					value: ScreenEnum.selectDevice,
			  });
	};

	return (
		<>
			<div className='top less-padding pw'>
				<div className='password'>
					<h1>{t('PasswordHeadline')}</h1>
					<p className='p1'>{t('PasswordText')}</p>
					<PasswordStrengthMeter
						errorMessage={
							newPw === false ? t('PasswordErrorNew') : undefined
						}
						value={newPw ? newPw : ''}
						id='new-pw'
						onInput={e =>
							setNewPw((e.target as HTMLInputElement).value)
						}
						entropy={calculatedEntropyBits}
					/>

					<PasswordInput
						labelId='label-confirm-pw'
						labelHeadline={t('PasswordLabelConfirm')}
						errorMessage={
							confirmPw === false
								? t('PasswordErrorConfirm')
								: undefined
						}
						value={confirmPw ? confirmPw : ''}
						id='confirm-pw'
						placeholder={t('PasswordPlaceholderConfirm')}
						onInput={e =>
							setConfirmPw((e.target as HTMLInputElement).value)
						}
					/>

					<div className='buttons'>
						<button
							className='white'
							disabled={
								!newPw ||
								!confirmPw ||
								newPw.length < 16 ||
								confirmPw.length < 16 ||
								(calculatedEntropyBits !== undefined &&
									calculatedEntropyBits < 53)
							}
							onClick={() => {
								if (newPw === confirmPw) {
									newPw &&
										dispatch({
											type: 'SET_USER_PASSWORD',
											value: newPw,
										});
									props.triggerValidation
										? dispatch({
												type: 'SET_SCREEN',
												value: ScreenEnum.validation,
										  })
										: dispatch({
												type: 'SET_SCREEN',
												value: ScreenEnum.selectDevice,
										  });
									props.saveBtn && props.saveBtn();
								} else {
									// does not match confirm pw
									setConfirmPw(false);
								}
							}}
						>
							{t('PasswordSave')}
						</button>
						<button
							className={`black${
								state.userPassword ? ' with-border' : ''
							} `}
							onClick={cancelBtnHandler}
						>
							{t('Cancel')}
						</button>
						{state.userPassword && (
							<button
								className='black delete'
								onClick={deletePasswordHandler}
							>
								{t('DeletePassword')}
							</button>
						)}
						{showDeletePasswordWarning && (
							<ExplanationOverlay
								button={() => {
									setShowDeletePasswordWarning(false);
									dispatch({
										type: 'SET_USER_PASSWORD',
										value: null,
									});
									props.setkvps && props.setkvps();
									props.passwordToggle &&
										props.passwordToggle();
								}}
								cancel={() => {
									setShowDeletePasswordWarning(false);
								}}
								showButton={true}
								title={i18next.t('DeletePassword')}
								text={'DeletePasswordsTexts'}
							/>
						)}
					</div>
				</div>
			</div>
		</>
	);
}
