import React, { useCallback, useReducer } from 'react';
import { ScreenEnum } from '../enums';
import { getLanguage } from '../utils/getLanguage';
import { AppState } from './interfaces';
import AppStateContext from './context';
import { reducer } from './reducer';
import data from '../data.json';
import * as packageJson from '../../package.json';
import { getSetup } from '../utils/getSetup';

export default function AppStateProvider(props: { children: React.ReactNode }) {
	const setup = getSetup();

	// brand, market, kvps, password, device, url, language=de-DE, user-password etc.,
	// label=true, redirect=false, orientation=default|right|down|left, in-frame=true, apk=true, change-device=true
	const params = new URLSearchParams(window.location.search);

	let localStorageStateString = localStorage.getItem('state');
	let localStorageState = localStorageStateString
		? (JSON.parse(localStorageStateString) as AppState)
		: null;

	if (localStorageState) {
		if (params.get('software-check') === 'true') {
			localStorageState.screen = ScreenEnum.launch;
			localStorageState.softwareCheck = true;
		} else {
			localStorageState = null;
		}
	}

	//for statistics tracking
	localStorage.setItem('SetupVersion', packageJson.version);

	let account: { [key: string]: string | null } = {};
	if (params.get('apk') === 'true') {
		// read account.ini from url
		params
			.get('account')
			?.split('\n')
			.forEach(line => {
				line = line.trim();
				if (!line || line.startsWith(';')) return;
				let [key, value] = line.includes('=')
					? line.split('=')
					: [line, null];
				account[key] = value?.trim() || null;
			});

		account.url = account.url ? new URL(account.url).origin : null;
		const relevant = account.url?.includes('.did.b12-ssp.de')
			? account.url
					?.replace('https://', '')
					.split('.did.b12-ssp.de')[0]
					.replace('.qs', '')
			: null;
		if (relevant?.includes('.')) {
			account.market = relevant.slice(0, 3);
			account.kvps = relevant.slice(3, relevant.indexOf('.'));
			account.brand = relevant.slice(
				relevant.indexOf('.') + 1,
				relevant.indexOf('-')
			);
			account.marketEnding = relevant.includes('-')
				? relevant.slice(relevant.indexOf('-') + 1)
				: '';
		} else if (relevant) {
			account.brand = relevant.slice(0, relevant.indexOf('-'));
			account.marketEnding = relevant.includes('-')
				? relevant.slice(relevant.indexOf('-') + 1)
				: '';
		}
	}

	const brandObj = (data.brands as Array<any>).find(
		(b: { name: any }) =>
			b.name === (account.brand || params.get('brand') || setup?.brand)
	);

	const INITIAL_STATE: AppState = localStorageState || {
		brand: account.brand || params.get('brand') || setup?.brand || null,
		market: brandObj
			? (brandObj.markets as Array<any>).find(
					(m: { name: string }) =>
						m.name.toLowerCase() ===
						(
							account.market ||
							params.get('market') ||
							setup?.market.name ||
							undefined
						)?.toLowerCase()
			  ) ||
			  (brandObj.markets as Array<any>).find(
					(m: { ending: string }) =>
						m.ending.toLowerCase() ===
						account.marketEnding?.toLowerCase()
			  ) ||
			  null
			: null,
		kvps: [account.kvps || params.get('kvps') || setup?.kvps].map(k =>
			k && /^\d+$/.test(k) ? k : null
		)[0],
		password:
			account.password ||
			params.get('password') ||
			setup?.password ||
			null,
		devices:
			account.name && account.id
				? [`${account.name} (${account.id})`]
				: null,
		device: account.id || params.get('device') || setup?.device || null,
		url: account.url || params.get('url') || setup?.url || null,
		language: getLanguage(data.languages, setup, params.get('language')),
		userPassword:
			params.get('user-password') || setup?.userPassword || null,
		screen: ScreenEnum.launch,
		errors: {
			validationError: 0,
			passwordError: 0,
			urlError: 0,
		},
		goToLabel: false,
		apk:
			params.get('apk') === 'true' ||
			window.location.href.includes('chrome'),
		setup: setup,
		dataJson: data,
		softwareCheck: false,
		offline: !navigator.onLine,
		chromeboxLabel: setup?.chromeboxLabel || false,
	};

	const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

	return (
		<AppStateContext.Provider
			value={useCallback(
				() => ({
					state,
					dispatch,
				}),
				[state, dispatch]
			)()}
		>
			{props.children}
		</AppStateContext.Provider>
	);
}
