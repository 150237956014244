import React from 'react';
import './styles.scss';
import { Placeholder } from '../../assets/Icons';
import Logo from '../../../public/logo512.png';

export default function Label() {
	const orientation = new URLSearchParams(window.location.search).get(
		'orientation'
	);
	// important to use window.opener.location as own window.location doesn't have the right parameters set
	const chromebox =
		window.opener.location.href.includes('chromebox') ||
		window.opener.location.href.includes('Chromebox');

	return (
		<div className='label'>
			{orientation ? (
				<div className={`orientation-indicator ${orientation}`}>
					{Placeholder}
				</div>
			) : chromebox ? (
				<div>
					<img src={Logo} alt='DID logo' />
				</div>
			) : (
				<></>
			)}
		</div>
	);
}
